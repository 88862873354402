body {
  margin: 0;
  font-family: 'Eurostile', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.devhero {
  padding: 160px 0 100px 0;
  margin-top: 0;
}

#contact {
  padding: 20px;
  margin-top: 100px;
  margin-bottom: 50px;
  background-color: rgba(145, 161, 71, 0.2);
}

.wrap {
  border-right: 0.08em solid #666;
}

.modal-90w {
  min-width: 90%;
}

.selectedSlide {
  opacity: 60%;
}

.activeSlide {
  cursor: pointer;
}

.danger {
  color: crimson;
}

.success {
  color: aquamarine;
}

.galleryContainer {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: center;
}

.gallery-item {
  padding: 10px;
}

.gallery-image {
  height: 200px;
}

.gallery-image:hover {
  opacity: 75%;
  cursor: pointer;
}

.artpage {
  justify-content: center;
  text-align: center;
}

.iframecontainer {
  width:560px;
  height:315px;
  margin: 25px;
}

.formo {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.homebtn {
  position: relative;
  text-align: center;
  text-decoration: none;
  opacity: 70%;
}

.homebtn:hover {
  opacity: 100%;
}

.devprojectitem {
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .iframecontainer {
      width: 187px;
      height: 105px;
  }
}